import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  appConfig: {},
  layersAll: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async editAppConfig({ dispatch }, payload) {
    await api.put('config', payload);
    await dispatch('getAppConfig', { withLayers: true });
  },
  async getAppConfig({ commit, dispatch, rootState }, { withLayers = false } = {}) {
    const r = await api.get('config');
    let layers = [];
    if (withLayers) {
      const rLayers = await dispatch('getLayers');
      const orderedLayers = (r.data.data.order || [])
        .map(layer => rLayers.find(rLayer => rLayer.id === layer))
        .filter(layer => !!layer);
      const visibleLayers = r.data.data.layers_visible || [];
      layers = orderedLayers.map(layer => {
        if (layer.type === 'features_layer') {
          const { filter_expression, form_schema, geometry_type, id, name, style_web, supports_mvt, type } = layer;
          return {
            filters: filter_expression || undefined,
            form_schema,
            geometry_type,
            id,
            name,
            style: style_web,
            supports_mvt,
            type,
            visible: visibleLayers.includes(id),
            labels_visible: true,
            opacity: 1,
          };
        } else if (layer.type === 'service_layer') {
          const { id, name, parameters, service_layers_names, service_type, srid, type, url } = layer;
          return {
            id,
            name,
            parameters,
            service_layers_names,
            service_type,
            srid,
            type,
            url,
            visible: visibleLayers.includes(id),
            opacity: 1,
          };
        }
      });
    }
    const project = {
      zoom: r.data.data.zoom,
      map_center: r.data.data.coordinates,
      layers,
    };
    rootState.map.project = project;
    commit('SET_APP_CONFIG', r.data.data);
  },
  async getLayer(store, id) {
    const r = await api.get(`/layers/${id}`);
    return r.data.data;
  },
  async getLayers() {
    const r = await api.get('/layers/list');
    return r.data.data;
  },
  async getLayersAll({ commit }) {
    const r = await api.get('/layers');
    commit('SET_LAYERS_ALL', r.data.data);
  },
  async putLayers(store, payload) {
    await api.put('/layers', payload);
  },
  async putLayersVisible(store, payload) {
    await api.put('/layers_visible', payload);
  },
  async putFavicon(store, data) {
    await api.put('/config/favicon/upload', data, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async putLogo(store, data) {
    await api.put('/config/logo/upload', data, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
